import styled from "styled-components";

export default function HorizSpacer() {
  return(
    <ButtonSpacer />
  );
}

const ButtonSpacer = styled.div`
  width: 20px;
`;